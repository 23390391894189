import { useLocation } from '@remix-run/react'

// CSS is already placed in index.html

const MT_SPINNER_URL =
  'https://res.cloudinary.com/mixtiles/image/upload/v1720599135/assets/spinners/splash-mt_e3onfp.svg'
const ART_SPINNER_URL =
  'https://res.cloudinary.com/mixtiles/image/upload/v1720599135/assets/spinners/splash-art_h4xrjs.svg'

export default function SplashSpinner() {
  const location = useLocation()
  const isArt = location.pathname.startsWith('/art')
  return (
    <>
      <img
        className="site-spinner"
        src={isArt ? ART_SPINNER_URL : MT_SPINNER_URL}
        alt=""
        width="34"
        height="34"
      />
    </>
  )
}
